import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Strings from "../constants/Strings";

const NotFoundPage = () => (
  <Layout override="marginStandard">
    <SEO title={Strings.Missing.title} />
    <h1>{Strings.Missing.title}</h1>
    <p>{Strings.Missing.message}</p>
  </Layout>
);

export default NotFoundPage;
